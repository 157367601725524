import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from 'react-bootstrap';
import logo from '../../src/images/LOGO.png';
import noProfileImage from '../../src/images/no-profile.png';
import { logoutUser } from "../Actions/AuthAction";
// import { onNavbarClick } from "../Actions/AlertAction";

const Navbar = ({ activeNav }) => {

    const dispatch = useDispatch();
    // const activeNav = useSelector(state => state.alert.activeNavbar)
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand.data);
    const [isActive, setActive] = useState("");
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    const handleToggle = () => {
        setActive(!isActive);
    };

    const handleClick = (e) => {
        // let nav = e.target.name
        // dispatch(onNavbarClick(nav));
    };

    const logout = () => {
        dispatch(logoutUser());
    }

    return (
        <>

            <header className="appHeader">
                <div className="container-fluid">
                    <div className="appHeader-main">
                        <div className="logo">
                            <Link to={'/'}>
                                {/* <img alt="" className={`${rebrandData ? "logo-image-set" : ""} img-fluid`} src={rebrandData ? rebrandData.logo : logo} /> */}
                                <h1>[Company Name]</h1>
                            </Link>
                        </div>
                        <div className="navExpand" onClick={handleToggle}><i className="fa fa-bars" aria-hidden="true"></i></div>
                        <div className={`appHeader-right ${!isActive ? "" : "act"}`}>
                            <div className="appHeader-nav">
                                <ul>
                                    <li><NavLink className={`nav-link`} to="/dashboard" onClick={(e) => handleClick(e)} >Dashboard</NavLink></li>
                                    <li><NavLink className={`nav-link ${activeNav === "business" ? "active" : ""}`} to="/business" name="business" onClick={(e) => handleClick(e)} >Business</NavLink></li>
                                    <li><NavLink className={`nav-link ${activeNav === "customer" ? "active" : ""}`} to="/customer" name="clients" onClick={(e) => handleClick(e)} >Customers</NavLink></li>
                                    <li><NavLink className={`nav-link ${activeNav === "staff" ? "active" : ""}`} to="/staff" name="staff" onClick={(e) => handleClick(e)} >Staffs</NavLink></li>
                                    {/* {
                                        auth.user.is_client_account === "0" ? 
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/integration">Integrations</NavLink>
                                            </li>
                                            : ''
                                    } */}
                                    <li className="nav-item"><NavLink className="nav-link" to="/help-and-support" >Help</NavLink></li>
                                </ul>
                            </div>
                            <div className="appHeader-action">

                                <div className="action-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic2">
                                            <div className="dropProfileImage"><img alt="" src={auth.user ? auth.user.image : noProfileImage} /></div>
                                            <div>
                                                <span className="welcometxt">Welcome</span>
                                                <span className="profileName">{auth.user?.name}</span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-dark">
                                            {
                                                auth.user.is_client_account === "1" ? "" :
                                                    <Link className="dropdown-item" to="/profile">
                                                        <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                                    </Link>
                                            }
                                            {
                                                auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    <>

                                                        <Link className="dropdown-item" to="/privacy">
                                                            <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                        </Link>
                                                        {
                                                            auth.user.is_reseller_account === "0" ?
                                                                memberShip.includes("business") || memberShip.includes("bundle") ?
                                                                    <>
                                                                        <Link className="dropdown-item" to="/account-management">
                                                                            <span className="mr-2"><i className="fas fa-users" ></i></span>
                                                                            Account Management
                                                                        </Link>
                                                                    </> : ''
                                                                : ''
                                                        }
                                                    </>
                                                    : ''
                                            }
                                            {/* {
                                                auth.user.is_client_account === "0" && auth.user.is_reseller_account === "0" ?
                                                    memberShip.includes("dfy") || memberShip.includes("business") || memberShip.includes("bundle") || memberShip.includes("unlimitedlite") || memberShip.includes("unlimited") ?
                                                        <Link className="dropdown-item" to="/upgrades">
                                                            <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                                        </Link> : ''
                                                    : ''
                                            } */}
                                            {/* {
                                                auth.user.is_client_account === "0" && auth.user.is_reseller_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    memberShip.includes("professional") ?
                                                        <Link to='/reel-merge' className="dropdown-item">
                                                            <span className="mr-2">
                                                                <i className="fas fa-sign-out-alt"></i>
                                                            </span> Reel Merge
                                                        </Link> : "" : ""
                                            } */}
                                            <Link className="dropdown-item" to="/help-and-support">
                                                <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                            </Link>
                                            {
                                                auth.user.is_super_admin === "1" ?
                                                    <a className="dropdown-item" href={`https://superadmin.proservicesnearby.com/admin_dashboard?userId=${auth.user.id}`} target="blank">
                                                        <span className="mr-2"><i class="fa-solid fa-lock"></i></span> Admin Panel
                                                    </a>
                                                    : ''

                                            }
                                            {auth.user.is_client_account === "1" ? "" :
                                                <Link className="dropdown-item" to="/base-currency">
                                                    <span className="mr-2"><i class="fa fa-suitcase" aria-hidden="true"></i></span>Base Currency
                                                </Link>
                                            }
                                            <span className="dropdown-divider"></span>
                                            <Link className="dropdown-item" onClick={() => logout()}>
                                                <span className="mr-2">
                                                    <i className="fas fa-sign-out-alt"></i>
                                                </span> Sign Out
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export default React.memo(Navbar);